import Vue from 'vue'
import App from './App.vue'
// import router from './router'
import config from '@/config/index.js'
import service from './service/index.js'
// vue-custom-element by karol-f
import vueCustomElement from 'vue-custom-element'
// (optional) 'Custom elements polyfill'
import 'document-register-element/build/document-register-element'

import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)

// widget setup
Vue.use(vueCustomElement)

Vue.config.productionTip = false
Vue.prototype.service = service

// App.router = router
Vue.customElement(config.widgetName, App)
