<template>
  <div>
    <div id="atkp_chart_freq" style="width: 375px; height: 320px;"></div>
  </div>
</template>

<script>
import Papa from 'papaparse'
import * as echarts from 'echarts/core'
import { ScatterChart, LineChart } from 'echarts/charts'
import { GridComponent, MarkAreaComponent, GraphicComponent  } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
echarts.use([
  GridComponent, ScatterChart, LineChart, CanvasRenderer, MarkAreaComponent, GraphicComponent
])
export default {
  name: 'FreqGraphs',
  props: {
    freqSelect: Array,
    productId: String,
    highlightData: Object
  },
  data () {
    return {
      chart: null,
      xAxis: [],
      series: [],
      graphic: []
    }
  },
  watch: {
    freqSelect: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.fetchData()
        }
      },
      deep: true
    },
    productId: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.fetchData()
        }
      },
      deep: true
    }
  },
  mounted: function () {
    this.initEcharts()
  },
  methods: {
    initEcharts: function () {
      this.chart = echarts.init(document.getElementById('atkp_chart_freq'))
      this.addBackground()
      this.drawChart()
    },
    fetchData: function () {
      this.series = []
      this.addBackground()
      if (this.freqSelect.length <= 0) {
        this.drawChart()
      } else {
        const colors = ['#22C2EF', '#EF884E', '#FFC700']
        this.freqSelect.map((file, index) => {
          if (!file.url) return
          Papa.parse(file.url, {
            download: true,
            complete: res => {
              const data = []
              res.data.map(row => {
                if (parseFloat(row[0]) >= 20 && parseFloat(row[0]) <= 20000) {
                  data.push(row)
                }
              })
              const dataX = []
              const dataY = []
              data.pop()
              data.forEach(function (value, index, array) {
                dataX.push(Number(array[index][0]))
                dataY.push(Number(array[index][1]))
              })
              this.xAxis = dataX
              this.series.push(
                {
                  type: 'line',
                  smooth: true,
                  symbol: 'none',
                  clip: false,
                  data: dataY,
                  lineStyle: {
                    width: file.id === this.productId ? 3 : 1.5,
                    color: colors[index]
                  }
                }
              )
              this.drawChart()
            }
          })
        })
      }
    },
    addBackground: function () {
      this.series.push({
        type: 'scatter',
        data: [
          {
            value: [60, 10],
            label: {
              formatter: [
                '{titleBg|BASS     MIDRANGE     TREBLE}'
              ].join('\n'),
              rich: {
                titleBg: {
                }
              }
            }
          },
          {
            value: [60, 2.5],
            label: {
              formatter: [
                '{midBg|TOUCH GRAPH}'
              ].join('\n'),
              rich: {
                titleBg: {
                }
              }
            }
          },
          {
            value: [60, -5],
            label: {
              formatter: [
                '{bottomBg|SUB     LOW     LO MID    MID     HI MID     HI     AIR}'
              ].join('\n'),
              rich: {
                titleBg: {
                }
              }
            }
          }
        ],
        markArea: {
          itemStyle: {
            color: 'rgba(0, 0, 0, 0.25)'
          },
          data: []
        },
        legendHoverLink: false,
        cursor: 'auto',
        symbolSize: 0,
        label: {
          show: true,
          borderColor: '#555',
          borderWidth: 0,
          borderRadius: 5,
          fontSize: 12,
          color: '#416470',
          rich: {
            titleBg: {
              fontSize: 20,
              fontWeight: 'bolder',
              padding: [25, 0, 0, 0],
              width: '100%'
            },
            midBg: {
              fontSize: 18,
              fontWeight: 'bolder',
              padding: [0, 0, 0, 0],
              width: '100%'
            },
            bottomBg: {
              fontSize: 13,
              fontWeight: 'bolder',
              padding: [0, 0, 17, 0],
              width: '100%'
            }
          }
        }
      })
    },
    drawChart: function () {
      if (!this.chart) return
      this.chart.setOption({
        backgroundColor: '#0E4E62',
        animation: false,
        grid: {
          left: 30,
          top: 20,
          right: 10,
          bottom: 30
        },
        xAxis: {
          data: this.xAxis,
          position: 'bottom',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            color: '#416470',
            fontSize: 12,
            interval: 20
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          min: -5,
          max: 10,
          splitNumber: 3,
          type: 'value',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            show: true,
            alignWithLabel: true,
            lineStyle: {
              color: '#416470',
              width: 2
            }
          },
          axisLabel: {
            show: true,
            color: '#416470',
            fontSize: 12
          }
        },
        graphic: [],
        series: this.series
      }, true)
      const zr = this.chart.getZr()
      // getZr()获取确切位置
      zr.off('click')
      // 取消多次操作
      zr.on('click', (params) => {
        // 获取鼠标点击的实际位置
        const pointInPixel = [params.offsetX, params.offsetY]
        let pointInGrid = this.chart.convertFromPixel({ seriesIndex: 0 }, pointInPixel)
        // 转化实际位置为坐标系中的位置
        let xIndex = pointInGrid[0]
        var xData = this.xAxis[xIndex]
        let startArea = ''
        let endArea = ''
        let textHz = ''
        let textTips = ''
        // 判断应该设置哪个区域的阴影以及显示哪种弹窗信息
        if (xData) {
          const highlightData = { ...this.highlightData }
          for (let key in highlightData) {
            if (highlightData[key].min && highlightData[key].max) {
              if (parseFloat(xData) >= highlightData[key].min && parseFloat(xData) < highlightData[key].max) {
                for (let i = 0; i < this.xAxis.length; i++) {
                  if (parseFloat(this.xAxis[i]) <= highlightData[key].min && parseFloat(this.xAxis[i + 1]) >= highlightData[key].min) {
                    startArea = `${this.xAxis[i]}`
                  }
                  if (parseFloat(this.xAxis[i - 1]) <= highlightData[key].max && parseFloat(this.xAxis[i]) >= highlightData[key].max) {
                    endArea = `${this.xAxis[i]}`
                    break
                  }
                }
                if (!startArea && endArea) {
                  startArea = `${this.xAxis[0]}`
                }
                if (startArea && !endArea) {
                  endArea = `${this.xAxis[this.xAxis.length - 1]}`
                }
                if (highlightData[key].max >= 1000) {
                  textHz = `${highlightData[key].min / 1000} - ${highlightData[key].max / 1000} KHz`
                } else {
                  textHz = `${highlightData[key].min} - ${highlightData[key].max} Hz`
                }
                textTips = highlightData[key].desc
                break
              }
            }
          }
          // if (20 <= xData && xData < 67){
          //   startArea = '20'
          //   endArea = '67'
          //   textHz = '20-67 HZ'
          //   textTips = 'Brightness, attack, definition, sibilance, clarity, crispness, treble, and even a fourth text line'
          // } else if (67 <= xData && xData < 224){
          //   startArea = '67'
          //   endArea = '224'
          //   textHz = '67-224 HZ'
          //   textTips = 'Brightness, attack, definition, sibilance, clarity, crispness, treble, and even a fourth text line'
          // } else if (224 <= xData && xData < 750){
          //   startArea = '224'
          //   endArea = '750'
          //   textHz = '224-750 HZ'
          //   textTips = 'Brightness, attack, definition, sibilance, clarity, crispness, treble, and even a fourth text line'
          // } else if (750 <= xData && xData < 2500){
          //   startArea = '750'
          //   endArea = '2500'
          //   textHz = '0.75-2.5 KHZ'
          //   textTips = 'Brightness, attack, definition, sibilance, clarity, crispness, treble, and even a fourth text line'
          // } else if (2500 <= xData && xData < 8500){
          //   startArea = '2500'
          //   endArea = '8500'
          //   textHz = '2.5-8.5 KHZ'
          //   textTips = 'Brightness, attack, definition, sibilance, clarity, crispness, treble, and even a fourth text line'
          // } else if (8500 <= xData && xData <= 19000){
          //   startArea = '8500'
          //   endArea = '19000'
          //   textHz = '8.5-19 KHZ'
          //   textTips = 'Brightness, attack, definition, sibilance, clarity, crispness, treble, and even a fourth text line'
          // }
          // 设置阴影
          this.series[0].markArea.data = [
            [
              {
                xAxis: startArea
              },
              {
                xAxis: endArea
              }
            ]
          ]
          // 设置弹窗信息
          this.graphic = [
            {
              type: 'group',
              left: '9%',
              top: '16%',
              children: [
                {
                  type: 'rect',
                  z: 100,
                  left: 'center',
                  top: 'middle',
                  shape: {
                    width: 220,
                    height: 140,
                    r: [4]
                  },
                  style: {
                    fill: 'rgba(0, 0, 0, 0.25)',
                    stroke: '#555',
                    lineWidth: 0,
                    shadowBlur: 8,
                    shadowOffsetX: 3,
                    shadowOffsetY: 5,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: '-100px',
                  top: '-65px',
                  style: {
                    fill: '#B0B0B0',
                    width: 200,
                    overflow: 'break',
                    text: textHz,
                    font: '12px lato'
                  }
                },
                {
                  type: 'text',
                  z: 100,
                  left: 'center',
                  top: '-45px',
                  style: {
                    fill: 'white',
                    width: 200,
                    height: 100,
                    overflow: 'break',
                    lineOverflow: 'truncate',
                    text: textTips,
                    font: '16px lato',
                    lineHeight: 19
                  }
                }
              ]
            }
          ]
          this.chart.setOption({
            series: this.series,
            graphic: this.graphic
          })
        }
      })
    }
  }
}
</script>
