<template>
  <div class="" v-show="playerStatus === 'show'">
    <div class="atkp-mini" :style="{ right: playerPosition.mini.right, bottom: playerPosition.mini.bottom }" v-show="showType === 'mini'">
      <div class="atkp-mini-container">
        <img
          class="atkp-mini-logo" @click="changeShowType('small')"
          src="https://atk-player.s3.amazonaws.com/logos/logo_mini.png" alt=""
        />
        <div class="atkp-mini-close" @click="playerStatus = 'hide'">
          <div class="atkp-close-container">
            <img src="https://atk-player.s3.amazonaws.com/icons/icon_close.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="atkp-small" v-show="showType === 'small'">
      <div class="atkp-small-container">
        <div class="atkp-small-header">
          <img class="atkp-small-logo" @click="changeShowType('infos')" src="https://atk-player.s3.amazonaws.com/logos/logo_small.png" alt="" />
          <div class="atkp-small-mini" @click="changeShowType('mini')">
            <img src="https://atk-player.s3.amazonaws.com/icons/icon_mini.png" alt="" />
          </div>
        </div>
        <div class="atkp-small-products" v-show="!visibleProductsList">
          <div class="atkp-products-list">
            <div
              v-for="(product, index) in productsSelect" :key="product._id"
              :class="['atkp-product-item', product._id === productActive._id ? 'atkp-active' : '']"
              @click="selectProduct(product)"
            >
              <div class="atkp-product-content">
                <div class="atkp-product-container">
                  <div class="atkp-product-image">
                    <div class="atkp-pi-container" :style="{backgroundImage: `url('${flagBlindMode ? blindMicImage : (product.images.thumbnail.url || blindMicImage)}')`}"></div>
                  </div>
                  <div class="atkp-product-brand">
                    <span>{{ flagBlindMode ? 'UNKNOWN' : product.general.brand_name }}</span>
                  </div>
                  <div class="atkp-product-model">
                    <span>{{ flagBlindMode ? `Blind mode ${index + 1}` : product.general.model }}</span>
                  </div>
                  <div class="atkp-product-price">
                    <span>{{ flagBlindMode ? '$xx' : `${generateIp(product.price).symbol}${generateIp(product.price).price}` }}</span>
                  </div>
                  <div :class="['atkp-product-point', audiosPreload[product._id] ? 'atkp-active' : 'atkp-inactive']"></div>
                </div>
                <div
                  v-show="!flagBlindMode && tabsActive === 3 && tabFreqActive === 1"
                  :class="['atkp-product-freq-line', `atkp-freq-${index}`]"
                ></div>
              </div>
              <div class="atkp-product-line"></div>
            </div>
          </div>
          <div class="atkp-products-actions">
            <div class="atkp-pa-item" @click="openProductsLibrary">
              <img src="https://atk-player.s3.amazonaws.com/icons/icon_swap.png" alt="" />
              <span>Gear</span>
            </div>
            <div :class="['atkp-pa-item', 'atkp-pai-blind', flagBlindMode ? 'atkp-active' : 'atkp-inactive']" @click="toggleBlindMode">
              <img class="atkp-inactive" src="https://atk-player.s3.amazonaws.com/icons/icon_blind.png" alt="" />
              <img class="atkp-active" src="https://atk-player.s3.amazonaws.com/icons/icon_blind_active.png" alt="" />
              <span>Blind</span>
            </div>
            <div class="atkp-pa-item" @click="generateSessionShare">
              <img src="https://atk-player.s3.amazonaws.com/icons/icon_share.png" alt="" />
              <span>Share</span>
            </div>
          </div>
        </div>
        <div
          :class="['atkp-small-content', tabsActive === 3 && tabFreqActive === 1 ? 'freq-active' : '']"
          v-show="!visibleProductsList"
        >
          <div class="atkp-sc-tabs">
            <div
              :class="['atkp-sct-item', tabsActive === 1 ? 'atkp-active': '']"
              v-show="features.listening_guide.is_enable === 1"
            >
              <div class="atkp-scti-container" @click="changeTabsActive(1)">
                <img class="atkp-inactive" src="https://atk-player.s3.amazonaws.com/icons/icon_listen.png" alt="" />
                <img class="atkp-active" src="https://atk-player.s3.amazonaws.com/icons/icon_listen_active.png" alt="" />
              </div>
              <div class="atkp-scti-line"></div>
            </div>
            <div
              :class="['atkp-sct-item', tabsActive === 2 ? 'atkp-active': '']"
              v-show="features.discography.is_enable === 1"
            >
              <div class="atkp-scti-container" @click="changeTabsActive(2)">
                <img class="atkp-inactive" src="https://atk-player.s3.amazonaws.com/icons/icon_disco.png" alt="" />
                <img class="atkp-active" src="https://atk-player.s3.amazonaws.com/icons/icon_disco_active.png" alt="" />
              </div>
              <div class="atkp-scti-line"></div>
            </div>
            <div
              :class="['atkp-sct-item', tabsActive === 3 ? 'atkp-active': '']"
              v-show="features.frequency_graphs.is_enable === 1 || features.tech_specs.is_enable === 1"
            >
              <div class="atkp-scti-container" @click="changeTabsActive(3)">
                <img class="atkp-inactive" src="https://atk-player.s3.amazonaws.com/icons/icon_freq.png" alt="" />
                <img class="atkp-active" src="https://atk-player.s3.amazonaws.com/icons/icon_freq_active.png" alt="" />
              </div>
              <div class="atkp-scti-line"></div>
            </div>
            <div
              :class="['atkp-sct-item', tabsActive === 4 ? 'atkp-active': '']"
              v-show="features.images.is_enable === 1"
            >
              <div class="atkp-scti-container" @click="changeTabsActive(4)">
                <img class="atkp-inactive" src="https://atk-player.s3.amazonaws.com/icons/icon_image.png" alt="" />
                <img class="atkp-active" src="https://atk-player.s3.amazonaws.com/icons/icon_image_active.png" alt="" />
              </div>
              <div class="atkp-scti-line"></div>
            </div>
            <div class="atkp-sct-buy" @click="openBuyUrl" v-show="generateIp(buyButton).url">
              <span>Buy</span>
            </div>
          </div>
          <div class="atkp-sc-container" v-show="!flagBlindMode">
            <div :class="['atkp-scc-item', 'atkp-scc-listen', tabsActive === 1 ? 'atkp-active' : '']">
              <div class="atkp-sccl-marked" v-html="markedHtml(productActive.listening_guide.content)"></div>
            </div>
            <div :class="['atkp-scc-item', 'atkp-scc-discography', tabsActive === 2 ? 'atkp-active' : '']">
              <div class="atkp-sccd-search">
                <input type="text" placeholder="Search discography" @input="searchDiscography" />
                <div class="atkp-sccd-icon">
                  <img src="https://atk-player.s3.amazonaws.com/icons/icon_search.png" alt="" />
                </div>
              </div>
              <div class="atkp-sccd-list">
                <div
                  v-for="(item, index) in (visibleDiscography ? discographySearch : productActive.discography)"
                  class="atkp-sccd-item" :key="index"
                >
                  <div class="atkp-sccdi-info">
                    <div class="atkp-sccdi-titile">
                      <span>{{ `${item.artist} / ${item.song}` }}</span>
                    </div>
                    <div class="atkp-sccdi-sub-title">
                      <span>{{ item.producer_engineer }}</span>
                    </div>
                    <div class="atkp-sccdi-instruments">
                      <span>{{ item.instruments }}</span>
                    </div>
                  </div>
                  <div class="atkp-sccdi-links">
                    <a target="_blank" :href="item.spotify_link" v-show="item.spotify_link">
                      <img src="https://atk-player.s3.amazonaws.com/icons/icon_spotify.png" alt="" />
                    </a>
                    <a target="_blank" :href="item.tidal_link" v-show="item.tidal_link">
                      <img src="https://atk-player.s3.amazonaws.com/icons/icon_tidal.png" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div :class="['atkp-scc-item', 'atkp-scc-freq', tabsActive === 3 ? 'atkp-active' : '']">
              <div class="atkp-sccf-tabs">
                <div
                  :class="['atkp-sccf-tab-item', tabFreqActive === 1 ? 'atkp-active' : '']" @click="tabFreqActive = 1"
                  v-show="features.frequency_graphs.is_enable === 1"
                >
                  <span>FREQ GRAPHS</span>
                  <div class="atkp-sccdti-line"></div>
                </div>
                <div
                  :class="['atkp-sccf-tab-item', tabFreqActive === 2 ? 'atkp-active' : '']" @click="tabFreqActive = 2"
                  v-show="features.tech_specs.is_enable === 1"
                >
                  <span>TECH SPECS</span>
                  <div class="atkp-sccdti-line"></div>
                </div>
              </div>
              <div class="atkp-sccf-container">
                <div :class="['atkp-sccfc-freq', tabFreqActive === 1 ? 'atkp-active' : '']">
                  <FreqGraphs :freqSelect="freqSelect" :productId="productActive._id" :highlightData="highlightData" />
                </div>
                <div :class="['atkp-sccfc-tech', tabFreqActive === 2 ? 'atkp-active' : '']">
                  <div
                    class="atkp-sccfct-item" v-for="(specGroupValue, specGroupKey) in techSpecs" :key="specGroupKey"
                    v-show="checkFreqItem(specGroupValue, specGroupKey)"
                  >
                    <div class="atkp-sccfcti-header" @click="toggleSpecTech(specGroupValue.name)">
                      <div class="atkp-sccfctih-container">
                        <img
                          :class="[techSpecsActive.includes(specGroupValue.name) ? 'atkp-active' : '']"
                          src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_up_dark.png" alt=""
                        />
                        <img
                          :class="[techSpecsActive.includes(specGroupValue.name) ? '' : 'atkp-active']"
                          src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_down_dark.png" alt=""
                        />
                        <span>{{ specGroupValue.name }}</span>
                      </div>
                    </div>
                    <div :class="['atkp-sccfcti-content', [techSpecsActive.includes(specGroupValue.name) ? 'atkp-active' : '']]">
                      <div class="atkp-sccfctic-spec">
                        <div class="atkp-sccfctic-title"></div>
                        <div class="atkp-sccfctic-text atkp-title" v-for="product in productsSelect"  :key="product._id">
                          <span>{{ product.general.model }}</span>
                        </div>
                      </div>
                      <div
                        class="atkp-sccfctic-spec" v-for="(specValue, specKey) in specGroupValue.content"  :key="specKey"
                        v-show="checkFreqSpec(specGroupKey, specKey)"
                      >
                        <div class="atkp-sccfctic-title">
                          <span>{{ specValue }}</span>
                        </div>
                        <div class="atkp-sccfctic-text" v-for="product in productsSelect"  :key="product._id">
                          <span>{{ generateSpecValue(specKey, product.tech_specs[specGroupKey][specKey]) }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :class="['atkp-scc-item', 'atkp-scc-images', tabsActive === 4 ? 'atkp-active' : '']">
              <div class="atkp-scci-container" v-show="productActive.images.group_360.length > 0">
                <Images360 :group360="productActive.images.group_360" disableZoom autoplay :loop="9999" />
              </div>
            </div>
          </div>
          <div :class="['atkp-sc-blind', flagBlindMode ? 'atkp-active' : '']">
            <div class="atkp-scb-content">
              <div class="atkp-scbc-section">
                <div class="atkp-scbc-title">
                  <span>BLIND MODE</span>
                </div>
                <div class="atkp-scbc-sub-title">
                  <span>MICROPHONE</span>
                </div>
                <div class="atkp-scbc-order">
                  <span>{{ blindProductIndex }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="['atkp-small-player', visibleSources ? 'atkp-sources' : '']" v-show="!visibleProductsList">
          <div class="atkp-sources-container">
            <div class="atkp-sources-header">
              <div class="atkp-sources-tab" @click="triggleSources('atk')">
                <img :class="[visibleSourcesAtk ? 'atkp-active' : 'atkp-inactive']" src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_up.png" alt="" />
                <img :class="[visibleSourcesAtk ? 'atkp-inactive' : 'atkp-active']" src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_down.png" alt="" />
                <span>BY AUDIO TEST KITCHEN</span>
              </div>
              <div class="atkp-sources-close" @click="visibleSources = false">
                <img src="https://atk-player.s3.amazonaws.com/icons/icon_close_full.png" alt="" />
              </div>
            </div>
            <div class="atkp-sources-content">
              <div class="atkp-scsongs-container">
                <div class="atkp-sc-atk">
                  <div class="atkp-sources-songs" v-show="visibleSourcesAtk">
                    <div
                      v-for="song in songs"  :key="song._id" @click="selectSong(song)"
                      :class="['atkp-songs-item', songActive._id === song._id ? 'atkp-active' : '']"
                      v-show="checkSource(song)"
                    >
                      <div class="atkp-ssi-name">
                        <span>{{ song.name }}</span>
                      </div>
                      <div class="atkp-ssi-writer">
                        <span>{{ song.song_writer }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="atkp-sc-my" v-show="features.my_audio.is_enable === 1">
                  <div class="atkp-scm-header">
                    <div class="atkp-sources-tab" @click="triggleSources('my')">
                      <img :class="[visibleSourcesMy ? 'atkp-active' : 'atkp-inactive']" src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_up.png" alt="" />
                      <img :class="[visibleSourcesMy ? 'atkp-inactive' : 'atkp-active']" src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_down.png" alt="" />
                      <span>{{ `BY ${`${instanceData.general.brand_name}`.toUpperCase()}` }}</span>
                    </div>
                  </div>
                  <div class="atkp-sources-songs" v-show="visibleSourcesMy">
                    <div
                      v-for="song in songsMy"  :key="song._id" @click="selectSong(song)"
                      :class="['atkp-songs-item', songActive._id === song._id ? 'atkp-active' : '']"
                      v-show="checkSource(song)"
                    >
                      <div class="atkp-ssi-name">
                        <span>{{ song.name }}</span>
                      </div>
                      <div class="atkp-ssi-writer">
                        <span>{{ song.song_writer }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="atkp-sources-instruments" v-show="visibleInstruments">
                <div
                  v-for="instrument in instruments[songActive._id]"  :key="instrument._id"
                  :class="['atkp-instruments-item', instrumentActive._id === instrument._id ? 'atkp-active' : '']"
                  @click="selectInstrument(instrument)"
                >
                  <div class="atkp-sii-name">
                    <span>{{ instrument.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="atkp-player-container">
            <div class="atkp-audio" @click="visibleSources = !visibleSources">
              <img class="atkp-icon-music" src="https://atk-player.s3.amazonaws.com/icons/icon_music.png" alt="" />
              <span v-show="audiosPreload[productActive._id]" class="atkp-name-song">{{ songActive.name }}</span>
              <span v-show="audiosPreload[productActive._id]" class="atkp-name-instrument">{{ instrumentActive.name }}</span>
              <span v-show="productActive._id && !audiosPreload[productActive._id]" class="atkp-name-empty">Select an available source</span>
            </div>
            <div class="atkp-waveform">
              <div class="atkp-icon-play" @click="doPlayPause()">
                <img :class="[playState ? 'atkp-inactive' : 'atkp-active']" src="https://atk-player.s3.amazonaws.com/icons/icon_play.png" alt="" />
                <img :class="[playState ? 'atkp-active' : 'atkp-inactive']" src="https://atk-player.s3.amazonaws.com/icons/icon_pause.png" alt="" />
              </div>
              <div class="atkp-wavesurfer" ref="atkp_player_wavesurfer"></div>
              <div class="atkp-wavesuerfer-loading" v-show="loadingWavesurfer">
                <div class="atkp-wl-container">
                  <img src="https://atk-player.s3.amazonaws.com/icons/icon_loading.gif" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="atkp-small-products-list" v-show="visibleProductsList">
          <div class="atkp-sml-header">
            <div class="atkp-sml-info">
              <span class="atkp-smlh-title">Mics</span>
              <span class="atkp-smlh-desc">Choose up to 3</span>
            </div>
            <div class="atkp-sml-done" @click="orderDone">
              <button class="atkp-sml-btn">Done</button>
            </div>
          </div>
          <div class="atkp-sml-container">
            <div class="atkp-smlc-brands" v-show="otherProducts.length > 0">
              <div :class="['atkp-smlcb-item', brandActive === 1 ? 'atkp-active' : '']" @click="brandActive = 1">
                <div class="atkp-smlcbi-name">
                  <span>{{ instanceData.general.brand_name }}</span>
                </div>
                <div class="atkp-smlcbi-line"></div>
              </div>
              <div :class="['atkp-smlcb-item', brandActive === 2 ? 'atkp-active' : '']" @click="brandActive = 2">
                <div class="atkp-smlcbi-name">
                  <span>Other</span>
                </div>
                <div class="atkp-smlcbi-line"></div>
              </div>
            </div>
            <div class="atkp-smlc-list" v-show="brandActive === 1">
              <div
                v-for="(product, index) in products" :key="product._id"
                :class="['atkp-smlc-product', product.order ? 'atkp-active' : '']"
                @click="orderProduct('self', product, index)"
              >
                <div class="atkp-smlcp-container">
                  <div class="atkp-smlcp-image">
                    <div class="atkp-smlcp-ibg" :style="{backgroundImage: `url('${product.images.thumbnail.url || blindMicImage}')`}"></div>
                  </div>
                  <div class="atkp-smlcp-brand">
                    <span>{{ product.general.brand_name }}</span>
                  </div>
                  <div class="atkp-smlcp-model">
                    <span>{{ product.general.model }}</span>
                  </div>
                  <div class="atkp-smlcp-price">
                    <span>{{ `${generateIp(product.price).symbol}${generateIp(product.price).price}` }}</span>
                  </div>
                  <div class="atkp-smlcp-order">
                    <span>{{ product.order }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="atkp-smlc-list" v-show="brandActive === 2">
              <div
                v-for="(product, index) in otherProducts" :key="product._id"
                :class="['atkp-smlc-product', product.order ? 'atkp-active' : '']"
                @click="orderProduct('other', product, index)"
              >
                <div class="atkp-smlcp-container">
                  <div class="atkp-smlcp-image">
                    <div class="atkp-smlcp-ibg" :style="{backgroundImage: `url('${product.images.thumbnail.url || blindMicImage}')`}"></div>
                  </div>
                  <div class="atkp-smlcp-brand">
                    <span>{{ product.general.brand_name }}</span>
                  </div>
                  <div class="atkp-smlcp-model">
                    <span>{{ product.general.model }}</span>
                  </div>
                  <div class="atkp-smlcp-price">
                    <span>{{ `${generateIp(product.price).symbol}${generateIp(product.price).price}` }}</span>
                  </div>
                  <div class="atkp-smlcp-order">
                    <span>{{ product.order }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="atkp-small-session" v-show="flagSessionModal">
          <div class="atkp-ss-container">
            <div class="atkp-ssc-icon">
              <img src="https://atk-player.s3.amazonaws.com/logos/logo_mini.png" alt="" />
            </div>
            <div class="atkp-ssc-title">
              <span>Share this comparison! </span>
            </div>
            <div class="atkp-ssc-desc">
              <span>Copy the URL, send it, and your recipient will be able to interact with the Showroom exactly as you have it.</span>
            </div>
            <div class="atkp-ssc-link atkp-text-wrap">
              <a :href="sessionLink" target="_blank">{{ sessionLink }}</a>
            </div>
            <div class="atkp-ssc-copy" v-show="!flagSessionCopy">
              <button @click="copySessionLink">Copy URL</button>
            </div>
            <div class="atkp-ssc-copied" v-show="flagSessionCopy">
              <button>URL copied!</button>
            </div>
            <div class="atkp-ssc-close" @click="flagSessionModal = false">
              <img src="https://atk-player.s3.amazonaws.com/icons/icon_close_full.png" alt="" />
            </div>
            <div class="atkp-ssc-shadow" v-show="loadingSession">
              <div class="atkp-sscs-container">
                <div class="atkp-sscsc-loading">
                  <img src="https://atk-player.s3.amazonaws.com/icons/icon_loading.gif" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="atkp-small-shadow" v-show="flagSessionModal"></div>
        <div class="atkp-small-loading" v-show="loadingInstance">
          <div class="atkp-sl-container">
            <img src="https://atk-player.s3.amazonaws.com/icons/icon_loading.gif" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="atkp-infos" v-show="showType === 'infos'">
      <div class="atkp-infos-container">
        <div class="atkp-infos-header">
          <div class="atkp-ih-title">
            <span>Showroom powered by</span>
          </div>
          <div class="atkp-ih-logo" @click="changeShowType('small')">
            <img src="https://atk-player.s3.amazonaws.com/logos/logo_small.png" alt="" />
          </div>
        </div>
        <div class="atkp-infos-tabs">
          <img @click="changeInfosTab('left')" src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_left.png" alt="" />
          <span>{{ infosTabs[infosActive].title }}</span>
          <img @click="changeInfosTab('right')" src="https://atk-player.s3.amazonaws.com/icons/icon_arrow_right.png" alt="" />
        </div>
        <div class="atkp-infos-content">
          <div class="atkp-ic-desc atkp-icd-home" v-show="infosActive === 0">
            <div class="atkp-icd-content">
              <div v-html="markedHtml(infosContent.home)"></div>
            </div>
          </div>
          <div class="atkp-ic-desc atkp-icd-listening" v-show="infosActive === 1">
            <div class="atkp-icd-content">
              <div v-html="markedHtml(infosContent.listening_tips)"></div>
            </div>
          </div>
          <div class="atkp-ic-desc atkp-icd-about" v-show="infosActive === 2">
            <div class="atkp-icd-content">
              <div v-html="markedHtml(infosContent.about_us)"></div>
            </div>
          </div>
          <div class="atkp-ic-desc atkp-icd-faq" v-show="infosActive === 3">
            <div class="atkp-icd-content">
              <div v-html="markedHtml(infosContent.faq)"></div>
            </div>
          </div>
        </div>
        <div class="atkp-infos-footer">
          <div class="atkp-if-container">
            <div class="atkp-ifc-list">
              <div class="atkp-ifc-item" @click="infosActive = 0" v-show="infosActive !== 0">
                <span>Home</span>
              </div>
              <div class="atkp-ifc-item" @click="infosActive = 1" v-show="infosActive !== 1">
                <span>Listening Tips</span>
              </div>
              <div class="atkp-ifc-item" @click="infosActive = 2" v-show="infosActive !== 2">
                <span>About Us</span>
              </div>
              <div class="atkp-ifc-item" @click="infosActive = 3" v-show="infosActive !== 3">
                <span>FAQ</span>
              </div>
              <div class="atkp-ifc-link">
                <a href="https://www.audiotestkitchen.com/" target="_blank">www.audiotestkitchen.com</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue'
const uuidv4 = require('uuid/v4')
import WaveSurfer from 'wavesurfer.js'
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions.js'
import marked from 'marked'
import _ from 'lodash'
import useClipboard from 'vue-clipboard3'
import Images360 from '@/components/Images360'
import FreqGraphs from '@/components/FreqGraphs'
export default {
  name: 'ATK-Player',
  props: {
    instance: String,
    status: String,
    params: Object
  },
  components: {
    Images360,
    FreqGraphs
  },
  watch: {
    instance: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.playerInstance = val
          this.getInstance()
        }
      },
      deep: true
    },
    status: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.playerStatus = val
          if (this.isMobile) {
            if (val === 'show') {
              document.body.style.overflow = 'hidden'
            } else {
              document.body.style.overflow = 'auto'
            }
          }
        }
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          if (val.status) {
            this.playerStatus = val.status
            if (val.status === 'show') {
              this.changeShowType('small')
            }
          }
          if (val.instance && this.playerInstance !== val.instance) {
            this.playerInstance = val.instance
            this.getInstance()
          }
          if (val.position) this.playerPosition = val.position
        }
      },
      deep: true
    }
  },
  data () {
    const productEmpty = {
      listening_guide: {},
      discographies: [],
      images: { group_360: [] },
      tech_specs: {
        basic: {}, amp_tra: {}, cap_dia: {}, patterns: {}
      }
    }
    const techSpecs = {
      basic: {
        name: 'BASIC',
        content: {
          interface: 'Interface',
          category: 'Category',
          type: 'Type',
          diaphragm_class: 'Diaphragm Class',
          ribbon_class: 'Ribbon Class',
          amplifier: 'Amplifier',
          power: 'Power'
          // pattern: 'Pattern'
        }
      },
      amp_tra: {
        name: 'AMPLIFIER & TRANSFORMER',
        content: {
          amplifier: 'Amplifier',
          transformer: 'Transformer',
          manufacturer: 'Made by',
          part_name: 'Part Name'
        }
      },
      cap_dia: {
        name: 'CAPSULE & DIAPHRAGM',
        content: {
          capsule_style: 'Capsule style',
          capsule_size: 'Capsule size in mm',
          capsule_materials: 'Capsule Materials',
          termination: 'Termination',
          diaphragm_size: 'Diaphragm size in mm',
          diaphragm_materials: 'Diaphragm materials'
        }
      },
      patterns: {
        name: 'PATTERNS',
        content: {
          selection: 'Pattern',
          multi_pattern: 'Multi-Pattern'
        }
      },
      controls: {
        name: 'CONTROLS',
        content: {
          pattern_selector: 'Pattern Selector',
          pad: 'Pad(s) in dB',
          high_pass: 'High Pass in Hz',
          other_filter: 'Other Filter',
          modes: 'Modes',
          special_controls: 'Special Controls'
        }
      },
      data: {
        name: 'DATA',
        content: {
          output_impedance: 'Output Impedance in ohms',
          frequency_range: 'Frequency Range',
          self_noise: 'Self-noise in dB A',
          max_spl: 'Max SPL in dB',
          signal_to_noise_ratio: 'Signal to Noise Ratio in dB(A)'
        }
      },
      physical: {
        name: 'PHYSICAL',
        content: {
          weight: 'Weight in g',
          height: 'Height in mm',
          max_diameter: 'Max Diameter in mm'
        }
      }
    }
    const features = {
      listening_guide: {},
      discography: {},
      frequency_graphs: {},
      tech_specs: {},
      images: {},
      my_audio: {}
    }
    const blindMicImage = 'https://atk-player.s3.amazonaws.com/images/mic_blind.png'
    const currentUrl = new URL(window.location.href)
    const sessionId = currentUrl.searchParams.get('atkp_session')
    const infosTabs = [
      { name: 'Home', title: 'All the product information in one place' },
      { name: 'Listening Tips', title: 'Listening Tips' },
      { name: 'About Us', title: 'About Us' },
      { name: 'FAQ', title: 'FAQ' }
    ]
    let visitorUuid = localStorage.getItem('atkp_visitor_uuid')
    if (!visitorUuid) {
      visitorUuid = uuidv4()
      localStorage.setItem('atkp_visitor_uuid', visitorUuid)
    }
    return {
      visitorUuid,
      sessionId,
      playerInstance: this.instance,
      playerStatus: this.status,
      playerPosition: { mini: { right: '36px', bottom: '36px' } },
      showType: 'small',
      instanceData: { general: {} },
      userCurrency: '',
      features,
      buyButton: {},
      products: [],
      otherProducts: [],
      brandActive: 1,
      productsSelect: [],
      freqSelect: [],
      highlightData: {},
      productEmpty,
      productActive: productEmpty,
      discographySearch: [],
      audiosPreload: {},
      audioActive: '',
      songs: [],
      songsMy: [],
      instruments: {},
      songActive: {},
      instrumentActive: {},
      techSpecsActive: [],
      techSpecs,
      tabsActive: 1,
      tabFreqActive: 1,
      blindProductIndex: 1,
      blindMicImage,
      sessionLink: '',
      infosContent: {},
      infosTabs,
      infosActive: 0,
      wavesurfers: [],
      region: {},
      wavesurferLoaded: false,
      playState: false,
      playTime: 0,
      isMobile: this.checkMobile(),
      visibleProductsList: false,
      visibleSources: false,
      visibleSourcesAtk: true,
      visibleSourcesMy: true,
      visibleInstruments: true,
      visibleDiscography: false,
      loadingInstance: false,
      loadingWavesurfer: false,
      flagBlindMode: false,
      flagSessionModal: false,
      flagSessionCopy: false,
      flagChangeSource: false,
      loadingSession: false
    }
  },
  created: function () {
    this.initVisitor()
    this.initPlayer()
    this.getInstance()
    this.getSession()
    this.getCurrency()
    this.getInfos()
    this.getHighlight()
    this.preloadAssets()
  },
  mounted () {
  },
  methods: {
    initVisitor: function () {
      const url = '/api/v1/atk_player/widget/visitors/login'
      const body = { uuid: this.visitorUuid, current_url: window.location.href }
      this.service.post(url, body).then(function () {
      }).catch(function (err) {
        console.error('initVisitor', err)
      })
    },
    initPlayer: function () {
      this.$nextTick(function () {
        const wavesurfer = WaveSurfer.create({
          container: this.$refs.atkp_player_wavesurfer,
          waveColor: '#49525A',
          progressColor: '#8C959D',
          height: 36,
          plugins: [
            Regions.create({
              dragSelection: {}
            })
          ]
        })
        const that = this
        wavesurfer.on('audioprocess', () => {
          this.playTime = wavesurfer.getCurrentTime()
        });
        wavesurfer.on('finish', () => {
          that.playState = false
        })
        wavesurfer.on('region-updated', () => {
          const regions = wavesurfer.regions.list
          const keys = Object.keys(regions)
          if (keys.length > 1) {
            regions[keys[0]].remove()
          }
        })
        wavesurfer.on('region-update-end', (region) => {
          this.handleRegion(region)
        })
        wavesurfer.on('region-created', (region) => {
          this.handleRegion(region)
        })
        this.wavesurfers.push(wavesurfer)
      })
    },
    handleRegion: function (region) {
      region.playLoop()
      this.playState = true
      this.region = { start: region.start, end: region.end }
      if (!region.hasDeleteButton) {
        const regionSelete = region.element
        const img = regionSelete.appendChild(document.createElement('img'))
        const handle = document.getElementsByTagName('handle')
        const wave = document.getElementsByTagName('wave')
        img.className = 'close'
        img.src = 'https://atk-player.s3.amazonaws.com/icons/icon_close_full.png'
        img.addEventListener('click', () => {
          region.remove()
          this.region = {}
        })
        img.title = 'Delete region'
        const css = {
          display: 'block',
          float: 'right',
          width: '14px',
          height: '14px',
          top: '-6px',
          right: '-6px',
          position: 'relative',
          zIndex: 11,
          cursor: 'pointer',
          backgroundColor:'white',
          borderRadius: '50%',
          padding: 0
        }
        const css2 = {
          height: '90%',
          backgroundColor: 'rgba(165, 194, 203, 0.2)',
          border: '1px solid #FFFFFF',
          borderRightWidth: '0px',
          borderLeftWidth: '0px',
          borderRadius: '3px',
          zIndex: 10,
        }
        const css3 = {
          backgroundColor: 'white',
          width: '1px'
        }
        const css4 = {
          overflow: 'visible'
        }
        region.style(img, css)
        region.style(regionSelete, css2)
        region.style(handle[0], css3)
        region.style(handle[1], css3)
        region.style(wave[0], css4)
        region.hasDeleteButton = true
      }
    },
    getInstance: function () {
      if (this.sessionId) return
      if (!this.playerInstance) return
      this.loadingInstance = true
      const url = `/api/v1/atk_player/widget/instances/${this.playerInstance}`
      const that = this
      this.service.get(url).then(function (res) {
        if (res.code === 200) {
          const data = res.data
          that.instanceData = data
          that.features = data.features
          if (data.buttons) that.buyButton = data.buttons
          if (data.products && data.products.length > 0) {
            that.products = data.products
          }
          if (data.other_products && data.other_products.length > 0) {
            that.otherProducts = data.other_products
          }
          let songActive = null
          let instrumentActive = null
          const productIndex = _.findIndex(that.products, { order: 1 })
          let product1 = null
          if (productIndex >= 0) product1 = that.products[productIndex]
          if (data.sources && data.sources.length > 0) {
            that.songs = data.sources
            data.sources.map(song => {
              that.instruments[song._id] = song.instruments
            })
            if (!product1) {
              that.songActive = data.sources[0]
              that.instrumentActive = data.sources[0].instruments[0]
            } else {
              that.songs.map(song => {
                if (songActive) return
                _.sortBy(song.instruments, ['order']).map(instrument => {
                  if (instrumentActive) return
                  const audioIndex = _.findIndex(product1.audio, { song_id: song._id, instrument_id: instrument._id })
                  if (audioIndex >= 0) {
                    songActive = song
                    instrumentActive = instrument
                  }
                })
              })
              that.songActive = songActive
              that.instrumentActive = instrumentActive
            }
          }
          if (data.my_sources && data.my_sources.length > 0) {
            that.songsMy = data.my_sources
            that.songsMy.map(song => {
              that.instruments[song._id] = song.instruments
            })
            if (product1 && !songActive) {
              that.songsMy.map(song => {
                if (songActive) return
                _.sortBy(song.instruments, ['order']).map(instrument => {
                  if (instrumentActive) return
                  const audioIndex = _.findIndex(product1.audio, { song_id: song._id, instrument_id: instrument._id })
                  if (audioIndex >= 0) {
                    songActive = song
                    instrumentActive = instrument
                  }
                })
              })
              that.songActive = songActive
              that.instrumentActive = instrumentActive
              if (songActive) that.visibleSourcesAtk = false
            }
          }
          if (!songActive) {
            that.songActive = that.songs[0]
            that.instrumentActive = that.songActive.instruments[0]
          }
          that.generateProductsSelect(that.products, that.otherProducts)
          that.triggerAnalyticsLaunch()
        } else {
          console.error('getInstance', res.msg)
        }
        that.loadingInstance = false
      }).catch(function (err) {
        console.error('getInstance', err)
        that.loadingInstance = false
      })
    },
    getSession: function () {
      if (!this.sessionId) return
      const url = `/api/v1/atk_player/widget/sessions/${this.sessionId}`
      const that = this
      this.loadingInstance = true
      this.service.get(url).then(function (res) {
        if (res.code === 200) {
          const data = res.data
          _.unset(data, 'wavesurfers')
          for (const key in data) {
            that[key] = data[key]
          }
          that.sessionId = ''
          if (that.productActive._id) {
            that.selectProduct(that.productActive)
          }
        } else {
          that.sessionId = ''
          that.getInstance()
          console.error('getSession', res.msg)
        }
        that.loadingInstance = false
      }).catch(function (err) {
        console.error('getSession', err)
        that.loadingInstance = false
      })
    },
    getCurrency: function () {
      const url = '/api/v1/atk_player/widget/country_currency'
      const that = this
      this.service.get(url).then(function (res) {
        if (res.code === 200) {
          that.userCurrency = res.data.currency
        } else {
          console.error('getCurrency', res.msg)
        }
      }).catch(function (err) {
        console.error('getCurrency', err)
      })
    },
    getInfos: function () {
      const url = '/api/v1/atk_player/widget/settings/infos'
      const that = this
      this.service.get(url).then(function (res) {
        if (res.code === 200) {
          that.infosContent = res.data.content
        } else {
          console.error('getInfos', res.msg)
        }
      }).catch(function (err) {
        console.error('getInfos', err)
      })
    },
    getHighlight: function () {
      const url = '/api/v1/atk_player/widget/settings/freq_graph'
      const that = this
      this.service.get(url).then(function (res) {
        if (res.code === 200) {
          that.highlightData = res.data.content
        } else {
          console.error('getHighlight', res.msg)
        }
      }).catch(function (err) {
        console.error('getHighlight', err)
      })
    },
    preloadAssets: function () {
      const assetsList = [
        'https://atk-player.s3.amazonaws.com/backgrounds/infos_header.png',
        'https://atk-player.s3.amazonaws.com/backgrounds/infos_footer.png',
        'https://atk-player.s3.amazonaws.com/backgrounds/infos_home.png',
        'https://atk-player.s3.amazonaws.com/backgrounds/infos_listening.png',
        'https://atk-player.s3.amazonaws.com/backgrounds/infos_faq.png',
        'https://atk-player.s3.amazonaws.com/backgrounds/infos_about.png'
      ]
      assetsList.map(url => {
        const preloadImg = new Image()
        preloadImg.src = url
      })
    },
    triggerAnalyticsLaunch: function () {
      const url = '/api/v1/atk_player/widget/analytics/launch'
      const body = { uuid: this.visitorUuid, instance_id: this.playerInstance, current_url: window.location.href }
      this.service.post(url, body).then(function () {
      }).catch(function (err) {
        console.error('triggerAnalyticsLaunch', err)
      })
    },
    generateProductsSelect: function (products, otherProducts) {
      const productsSelect = [...products, ...otherProducts]
      _.remove(productsSelect, (item) => { return !item.order })
      this.productsSelect = _.sortBy(_.take(productsSelect, 3), ['order'])
      this.freqSelect = []
      this.productsSelect.map(item => {
        this.freqSelect.push({ id: item._id, url: item.frequency_graph.url })
      })
      this.generateAudiosPreload()
      if (this.productsSelect.length > 0) {
        this.selectProduct(this.productsSelect[0])
      } else {
        this.selectProduct(this.productEmpty)
        this.emptyAudio()
      }
    },
    generateAudiosPreload: function () {
      const audios = {}
      const songId = this.songActive._id
      const instrumentId = this.instrumentActive._id
      this.productsSelect.map(product => {
        const audioIndex = _.findIndex(product.audio, { song_id: songId, instrument_id: instrumentId })
        if (audioIndex >= 0) {
          let audioUrl = product.audio[audioIndex].url
          if (audioUrl.indexOf('.mp3?t=') > 0 && audioUrl.indexOf('processed') > 0) {
            const audioUrlArray = audioUrl.split('.mp3?t=')
            audioUrl = `${audioUrlArray[0]}.mp3%3Ft%3D${audioUrlArray[1]}`
          }
          audios[product._id] = audioUrl
        } else {
          audios[product._id] = ''
        }
      })
      this.audiosPreload = audios
      for (const key in audios) {
        if (audios[key]) fetch(audios[key])
      }
    },
    changeShowType: function (type) {
      this.showType = type
      if (type === 'small' && !this.wavesurferLoaded) {
        if (this.productActive._id) {
          if (this.audiosPreload[this.productActive._id]) {
            this.playAudio(this.audiosPreload[this.productActive._id])
            this.wavesurferLoaded = true
          }
        }
      }
      if (this.isMobile) {
        if (type === 'small') {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }
      }
    },
    playAudio: async function (url) {
      await this.initPlayer()
      const waveLen = this.wavesurfers.length
      const wavesurfer = this.wavesurfers[waveLen - 1]
      const oldWavesurfer = this.wavesurfers[waveLen - 2]
      if (url) {
        this.loadingWavesurfer = true
        this.playState = oldWavesurfer.isPlaying()
        wavesurfer.load(url)
        wavesurfer.on('ready', () => {
          if (this.flagChangeSource) {
            this.region = {}
            this.flagChangeSource = false
            this.playTime = 0
          }
          if (this.playState) {
            wavesurfer.play.bind(wavesurfer)(this.playTime)
          } else {
            wavesurfer.seekTo(this.playTime / wavesurfer.getDuration())
          }
          this.loadingWavesurfer = false
          oldWavesurfer.destroy()
          if (this.region.start) {
            wavesurfer.addRegion(this.region)
          }
        })
      } else {
        oldWavesurfer.destroy()
      }
      if (this.wavesurfers.length > 2) {
        this.wavesurfers = this.wavesurfers.splice(-2)
      }
    },
    doPlayPause: function () {
      this.playState = !this.playState
      const wavesurfer = this.wavesurfers[this.wavesurfers.length - 1]
      wavesurfer.playPause.bind(wavesurfer)()
    },
    selectProduct: function (product) {
      if (this.loadingWavesurfer) return
      this.productActive = product
      const audioUrl = this.audiosPreload[product._id]
      if (audioUrl) {
        this.playAudio(audioUrl)
      } else {
        // const wavesurfer = this.wavesurfers[this.wavesurfers.length - 1]
        // wavesurfer.empty()
        this.region = {}
        if (product.recently_played) {
          let songIndex = _.findIndex(this.songs, { _id: product.recently_played.song_id })
          if (songIndex >= 0) {
            this.songActive = this.songs[songIndex]
            const instrumentIndex = _.findIndex(this.songActive.instruments, { _id: product.recently_played.instrument_id })
            if (instrumentIndex >= 0) {
              this.selectInstrument(this.songActive.instruments[instrumentIndex])
              this.visibleSourcesAtk = true
              this.visibleSourcesMy = false
              this.visibleInstruments = true
            }
          } else {
            songIndex = _.findIndex(this.songsMy, { _id: product.recently_played.song_id })
            this.songActive = this.songsMy[songIndex]
            const instrumentIndex = _.findIndex(this.songActive.instruments, { _id: product.recently_played.instrument_id })
            if (instrumentIndex >= 0) {
              this.selectInstrument(this.songActive.instruments[instrumentIndex])
              this.visibleSourcesAtk = false
              this.visibleSourcesMy = true
              this.visibleInstruments = true
            }
          }
        } else {
          let flagRePlay = false
          this.songActive.instruments.map(instrument => {
            if (flagRePlay) return
            const audioIndex = _.findIndex(this.productActive.audio, { song_id: this.songActive._id, instrument_id: instrument._id })
            if (audioIndex >= 0) {
              this.selectInstrument(instrument)
              flagRePlay = true
            }
          })
          const songIndex = _.findIndex(this.songs, { _id: this.songActive._id })
          if (songIndex >= 0) {
            this.songs.map(song => {
              if (flagRePlay) return
              song.instruments.map(instrument => {
                if (flagRePlay) return
                const audioIndex = _.findIndex(this.productActive.audio, { song_id: song._id, instrument_id: instrument._id })
                if (audioIndex >= 0) {
                  this.songActive = song
                  this.selectInstrument(instrument)
                  flagRePlay = true
                }
              })
            })
            if (!flagRePlay) {
              this.visibleSourcesAtk = false
              this.visibleSourcesMy = true
              this.visibleInstruments = true
              this.songsMy.map(song => {
                if (flagRePlay) return
                song.instruments.map(instrument => {
                  if (flagRePlay) return
                  const audioIndex = _.findIndex(this.productActive.audio, { song_id: song._id, instrument_id: instrument._id })
                  if (audioIndex >= 0) {
                    this.songActive = song
                    this.selectInstrument(instrument)
                    flagRePlay = true
                  }
                })
              })
            }
          } else {
            this.songsMy.map(song => {
              if (flagRePlay) return
              song.instruments.map(instrument => {
                if (flagRePlay) return
                const audioIndex = _.findIndex(this.productActive.audio, { song_id: song._id, instrument_id: instrument._id })
                if (audioIndex >= 0) {
                  this.songActive = song
                  this.selectInstrument(instrument)
                  flagRePlay = true
                }
              })
            })
            if (!flagRePlay) {
              this.visibleSourcesMy = false
              this.visibleSourcesAtk = true
              this.visibleInstruments = true
              this.songs.map(song => {
                if (flagRePlay) return
                song.instruments.map(instrument => {
                  if (flagRePlay) return
                  const audioIndex = _.findIndex(this.productActive.audio, { song_id: song._id, instrument_id: instrument._id })
                  if (audioIndex >= 0) {
                    this.songActive = song
                    this.selectInstrument(instrument)
                    flagRePlay = true
                  }
                })
              })
            }
          }
        }
      }
      if (this.flagBlindMode) {
        const productIndex = _.findIndex(this.productsSelect, { _id: product._id })
        this.blindProductIndex = productIndex + 1
      }
    },
    changeTabsActive: function (tabIndex) {
      if (this.flagBlindMode) return
      this.tabsActive = tabIndex
    },
    markedHtml: function (content) {
      return marked(content || '')
    },
    orderProduct: function (type, product, index) {
      const productOrder = product.order
      if (productOrder) {
        if (type === 'self') {
          this.products[index] = { ...this.products[index], order: '' }
        } else {
          this.otherProducts[index] = { ...this.otherProducts[index], order: '' }
        }
        if (productOrder <= 1) {
          let productIndex2 = _.findIndex(this.products, { order: 2 })
          if (productIndex2 >= 0) {
            this.products[productIndex2] = { ...this.products[productIndex2], order: 1 }
          } else {
            productIndex2 = _.findIndex(this.otherProducts, { order: 2 })
            if (productIndex2 >= 0) {
              this.otherProducts[productIndex2] = { ...this.otherProducts[productIndex2], order: 1 }
            }
          }
        }
        if (productOrder <= 2) {
          let productIndex3 = _.findIndex(this.products, { order: 3 })
          if (productIndex3 >= 0) {
            this.products[productIndex3] = { ...this.products[productIndex3], order: 2 }
          } else {
            productIndex3 = _.findIndex(this.otherProducts, { order: 3 })
            if (productIndex3 >= 0) {
              this.otherProducts[productIndex3] = { ...this.otherProducts[productIndex3], order: 2 }
            }
          }
        }
        this.generateProductsSelect([...this.products], [...this.otherProducts])
      } else {
        if (this.productsSelect.length < 3) {
          if (type === 'self') {
            this.products[index] = { ...this.products[index], order: this.productsSelect.length + 1 }
          } else {
            this.otherProducts[index] = { ...this.otherProducts[index], order: this.productsSelect.length + 1 }
          }
          this.generateProductsSelect([...this.products], [...this.otherProducts])
        }
      }
    },
    orderDone: function () {
      this.visibleProductsList = false
      this.playAudio(this.audiosPreload[this.productActive._id])
    },
    selectSong: function (song) {
      if (this.loadingWavesurfer) return
      this.visibleInstruments = true
      if (song._id !== this.songActive._id && song.instruments.length > 0) {
        // this.emptyAudio()
        this.flagChangeSource = true
        this.songActive = song
        this.instrumentActive = song.instruments[0]
        this.generateAudiosPreload()
        this.productsSelect.map(product => {
          if (this.audiosPreload[product._id]) {
            product.recently_played = {
              song_id: this.songActive._id,
              instrument_id: this.instrumentActive._id
            }
          }
        })
        this.playAudio(this.audiosPreload[this.productActive._id])
      }
    },
    selectInstrument: function (instrument) {
      if (this.loadingWavesurfer) return
      if (instrument._id !== this.instrumentActive._id) {
        // this.emptyAudio()
        this.flagChangeSource = true
        this.instrumentActive = instrument
        this.generateAudiosPreload()
        this.productsSelect.map(product => {
          if (this.audiosPreload[product._id]) {
            product.recently_played = {
              song_id: this.songActive._id,
              instrument_id: this.instrumentActive._id
            }
          }
        })
        this.playAudio(this.audiosPreload[this.productActive._id])
      }
    },
    emptyAudio: function () {
      const wavesurfer = this.wavesurfers[this.wavesurfers.length - 1]
      wavesurfer.empty()
      wavesurfer.clearRegions()
      this.region = {}
      this.playState = false
      this.playTime = 0
    },
    searchDiscography: function (e) {
      const text = e.target.value
      if (!text) {
        this.visibleDiscography = false
      } else {
        this.visibleDiscography = true
        this.discographySearch = _.filter(this.productActive.discography, function(o) {
          const flagArtist = o.artist ? o.artist.match(new RegExp(text, 'gi')) : false
          const flagSong = o.song ? o.song.match(new RegExp(text, 'gi')) : false
          const flagPE = o.producer_engineer ? o.producer_engineer.match(new RegExp(text, 'gi')) : false
          const flagInstruments = o.instruments ? o.instruments.match(new RegExp(text, 'gi')) : false
          return flagArtist || flagSong || flagPE || flagInstruments
        })
      }
    },
    toggleSpecTech: function (name) {
      if (this.techSpecsActive.includes(name)) {
        this.techSpecsActive = _.difference(this.techSpecsActive, [name])
      } else {
        this.techSpecsActive.push(name)
      }
    },
    toggleBlindMode: function () {
      if (!this.flagBlindMode) {
        this.flagBlindMode = true
        const productsLen = this.productsSelect.length
        if (productsLen > 1) {
          let orders = _.range(productsLen)
          orders = _.shuffle(orders)
          const productIds = _.map(this.productsSelect, '_id')
          for (let i = 0; i < productsLen; i++) {
            let productIndex = _.findIndex(this.products, { _id: productIds[i] })
            if (productIndex >= 0) {
              this.products[productIndex].order = orders[i] + 1
            } else {
              productIndex = _.findIndex(this.otherProducts, { _id: productIds[i] })
              this.otherProducts[productIndex].order = orders[i] + 1
            }
          }
          this.generateProductsSelect(this.products, this.otherProducts)
        }
      } else {
        this.flagBlindMode = false
      }
    },
    openProductsLibrary: function () {
      if (this.flagBlindMode) return
      this.visibleProductsList = true
    },
    generateSessionShare: function () {
      if (this.loadingSession) return
      this.loadingSession = true
      this.flagSessionCopy = false
      this.sessionLink = ''
      this.flagSessionModal = true
      const atkp_session = uuidv4()
      const sessionUrl = new URL(window.location.href)
      sessionUrl.searchParams.set('atkp_session', atkp_session)
      const session = {
        ...this.$data,
        uuid: atkp_session,
        sessionLink: sessionUrl.toString(),
        flagSessionModal: false,
        flagSessionCopy: false,
        wavesurfers: [],
        wavesurferLoaded: false,
        instanceData: { general: this.instanceData.general },
        visitorUuid: '',
        loadingSession: false
      }
      const url = '/api/v1/atk_player/widget/sessions'
      const that = this
      this.service.post(url, session).then(function (res) {
        if (res.code === 200) {
          that.sessionLink = session.sessionLink
        } else {
          console.error('generateSessionShare', res.msg)
        }
        that.loadingSession = false
      }).catch(function (err) {
        console.error('generateSessionShare', err)
        that.loadingSession = false
      })
    },
    copySessionLink: async function () {
      const { toClipboard } = useClipboard()
      try {
        await toClipboard(this.sessionLink)
        this.flagSessionCopy = true
      } catch (e) {
        console.error('copySessionLink', e)
      }
    },
    openBuyUrl: function () {
      const openUrl = this.generateIp(this.buyButton).url
      if (openUrl) window.open(openUrl)
    },
    generateSpecValue: function (key, val) {
      let str = ''
      if (Array.isArray(val)) {
        str = val.join(', ')
      } else if (typeof val == "boolean") {
        if (['transformer'].includes(key)) {
          str = val ? 'No' : 'Yes'
        } else {
          str = val ? 'Yes' : 'No'
        }
      } else {
        str = `${val}`.replace(/,/g, ', ')
      }
      return str
    },
    generateIp: function (data) {
      let obj = {}
      let objDefault = data.default || {}
      if (this.userCurrency && this.userCurrency !== objDefault.currency) {
        const dataIndex = _.findIndex(data.list, { currency: this.userCurrency })
        if (dataIndex > -1) obj = data.list[dataIndex]
      }
      if (!obj.currency) obj = objDefault
      return obj
    },
    changeInfosTab: function (type) {
      if (type === 'left') {
        if (this.infosActive === 0) {
          this.infosActive = 3
        } else {
          this.infosActive = this.infosActive - 1
        }
      } else {
        if (this.infosActive === 3) {
          this.infosActive = 0
        } else {
          this.infosActive = this.infosActive + 1
        }
      }
    },
    checkMobile() {
      if (screen.width <= 767) {
        return true
      } else {
        return false
      }
    },
    triggleSources: function (type) {
      if (type === 'atk') {
        this.visibleSourcesAtk = !this.visibleSourcesAtk
        const songIndex = _.findIndex(this.songs, { _id: this.songActive._id })
        if (songIndex >= 0) this.visibleInstruments = this.visibleSourcesAtk
      } else if (type === 'my') {
        this.visibleSourcesMy = !this.visibleSourcesMy
        const songIndex = _.findIndex(this.songsMy, { _id: this.songActive._id })
        if (songIndex >= 0) this.visibleInstruments = this.visibleSourcesMy
      }

    },
    checkFreqItem: function (itemValue, itemKey) {
      let flagCheck = false
      for (let specKey in itemValue.content) {
        this.productsSelect.map(product => {
          const specValue = product.tech_specs[itemKey][specKey]
          if (specValue && specValue.length > 0) flagCheck = true
          if (flagCheck) return
        })
        if (flagCheck) break
      }
      return flagCheck
    },
    checkFreqSpec: function (specGroupKey, specKey) {
      let flagCheck = false
      this.productsSelect.map(product => {
        const specValue = product.tech_specs[specGroupKey][specKey]
        if (specValue && specValue.length > 0) flagCheck = true
        if (flagCheck) return
      })
      return flagCheck
    },
    checkSource: function (song) {
      let flagAudio = false
      if (!this.productActive) {
        flagAudio = true
      } else if (song.instruments.length > 0) {
        song.instruments.map(instrument => {
          const audioIndex = _.findIndex(this.productActive.audio, { song_id: song._id, instrument_id: instrument._id })
          if (audioIndex >= 0) {
            flagAudio = true
            return
          }
        })
      }
      return flagAudio
    }
  }
}
</script>
<style scoped lang="scss">
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: initial;
}
img {
  display: block;
  padding: 0;
}
button {
  cursor: pointer;
}
input[type="text"], input[type="text"]:focus {
  outline: none 0 !important;
}
.atkp-text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.atkp-one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.atkp-mini, .atkp-small, .atkp-infos {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  z-index: 9999;
}
.atkp-mini {
  position: fixed;
  right: 36px;
  bottom: 36px;
  .atkp-mini-container {
    position: relative;
    border-radius: 50%;
    padding: 8px;
    background-color: #A5C2CB;
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.08);
    width: 64px;
    height: 64px;
    .atkp-mini-logo {
      width: 48px;
      cursor: pointer;
    }
    .atkp-mini-close {
      position: absolute;
      top: -12px;
      right: 0;
      width: 24px;
      height: 24px;
      overflow: hidden;
      background-color: white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        width: 12px;
        display: block;
      }
    }
  }
}
.atkp-small {
  position: fixed;
  right: 36px;
  bottom: 36px;
  color: black;
  text-align: left;
  .atkp-small-container {
    position: relative;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
    width: 375px;
    height: 764px;
    max-height: 90vh;
    border-radius: 8px;
    overflow: hidden;
    background-color: #F7F7FC;
    .atkp-small-header {
      height: 60px;
      padding: 16px 16px 12px;
      display: flex;
      justify-content: space-between;
      .atkp-small-logo {
        height: 32px;
        cursor: pointer;
      }
      .atkp-small-mini {
        cursor: pointer;
        img {
          margin-top: 10px;
          height: 2.5px;
        }
      }
    }
    .atkp-small-products {
      height: 157px;
      padding: 0 16px;
      display: flex;
      .atkp-products-list {
        width: 296px;
        padding-right: 3px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        &:after {
          content: "";
          width: 95px;
        }
        .atkp-product-item {
          width: 95px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
          .atkp-product-container {
            position: relative;
            padding: 8px 0 0;
            height: 148px;
            border-radius: 4px;
            text-align: center;
            .atkp-product-image {
              width: 64px;
              height: 64px;
              padding: 2px;
              margin: auto;
              .atkp-pi-container {
                width: 60px;
                height: 60px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }
            }
            .atkp-product-brand {
              font-size: 10px;
              color: #3B4D5D;
            }
            .atkp-product-model {
              overflow: hidden;
              font-size: 12px;
              height: 42px;
              font-weight: 700;
              line-height: 14px;
              color: #203041;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .atkp-product-price {
              font-size: 12px;
              color: #3B4D5D;
            }
            .atkp-product-point {
              position: absolute;
              top: 6px;
              left: 6px;
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #8BACB4;
              &.atkp-inactive {
                background-color: #C12D1C;
              }
            }
          }
          .atkp-product-freq-line {
            border-radius: 0 0 1px 1px;
            width: 64px;
            height: 4px;
            margin: auto;
            &.atkp-freq-0 {
              background-color: #22C2EF;
            }
            &.atkp-freq-1 {
              background-color: #EF884E;
            }
            &.atkp-freq-2 {
              background-color: #FFC700;
            }
          }
          .atkp-product-line {
            display: none;
            width: 64px;
            height: 3px;
            border-radius: 1px 1px 0 0;
            background: #203041;
            margin: 0 auto;
          }
          &.atkp-active {
            .atkp-product-container {
              background-color: #203041;
              .atkp-product-brand, .atkp-product-price {
                color: #B0B0B0;
              }
              .atkp-product-model {
                color: white;
              }
            }
            .atkp-product-freq-line {
              width: 96px;
            }
            .atkp-product-line {
              display: block;
            }
          }
        }
      }
      .atkp-products-actions {
        width: 42px;
        height: 152px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .atkp-pa-item {
          width: 40px;
          border-radius: 8px;
          cursor: pointer;
          text-align: center;
          img {
            width: 24px;
            margin: 3px auto 0;
          }
          span {
            font-size: 10px;
            color: #203041;
          }
          &.atkp-pai-blind {
            &.atkp-active {
              background-color: #203041;
              img.atkp-inactive {
                display: none;
              }
              img.atkp-active {
                display: block;
              }
              span {
                color: white;
              }
            }
            &.atkp-inactive {
              img.atkp-inactive {
                display: block;
              }
              img.atkp-active {
                display: none;
              }
            }
          }
        }
      }
    }
    .atkp-small-content {
      position: relative;
      height: 547px;
      padding: 88px 0 108px;
      max-height: calc(90vh - 217px);
      background-color: #A5C2CB;
      border-radius: 8px;
      .atkp-sc-tabs {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 16px 16px 0;
        height: 88px;
        display: flex;
        justify-content: space-between;
        background-color: #A5C2CB;
        .atkp-sct-item {
          .atkp-scti-container {
            width: 40px;
            height: 40px;
            border-radius: 8px;
            position: relative;
            cursor: pointer;
            img {
              width: 24px;
              margin: auto;
              padding-top: 8px;
              &.atkp-active {
                display: none;
              }
            }
          }
          .atkp-scti-line {
            width: 30px;
            height: 3px;
            background-color: #203041;
            border-radius: 1px;
            margin: 8px auto 0;
            display: none;
          }
          &.atkp-active {
            .atkp-scti-container {
              background-color: #203041;
              img {
                &.atkp-inactive {
                  display: none;
                }
                &.atkp-active {
                  display: block;
                }
              }
            }
            .atkp-scti-line {
              display: block;
            }
          }
        }
        .atkp-sct-buy {
          width: 52px;
          height: 42px;
          border-radius: 6px;
          cursor: pointer;
          background-color: white;
          text-align: center;
          line-height: 42px;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .atkp-sc-container {
        height: 100%;
        overflow-y: auto;
        .atkp-scc-item {
          display: none;
          &.atkp-active {
            display: block;
          }
        }
        .atkp-scc-listen {
          padding: 0 16px 16px;
        }
        .atkp-scc-discography {
          height: 100%;
          overflow: hidden;
          .atkp-sccd-search {
            height: 50px;
            position: relative;
            padding: 0 16px 8px;
            input {
              width: 100%;
              height: 42px;
              padding: 0 12px 0 52px;
              background-color: rgba(255,255,255,0.6);
              border-radius: 6px;
              font-size: 16px;
              color: #3B4D5D;
              border: none 0;
              font-family: 'Lato', sans-serif;
            }
            .atkp-sccd-icon {
              position: absolute;
              top: 9px;
              left: 28px;
              img {
                width: 24px;
              }
            }
          }
          .atkp-sccd-list {
            padding: 0 16px 16px;
            height: calc(100% - 50px);
            overflow-y: auto;
            .atkp-sccd-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-top: 16px;
              .atkp-sccdi-info {
                width: 257px;
                .atkp-sccdi-titile {
                  font-size: 16px;
                  color: #203041;
                }
                .atkp-sccdi-sub-title, .atkp-sccdi-instruments {
                  margin-top: 4px;
                  font-size: 14px;
                  color: #3B4D5D;
                }
              }
              .atkp-sccdi-links {
                width: 64px;
                display: flex;
                justify-content: space-between;
                a {
                  img {
                    width: 24px;
                  }
                }
              }
            }
          }
        }
        .atkp-scc-freq {
          height: 100%;
          overflow: hidden;
          .atkp-sccf-tabs {
            padding: 0 16px;
            display: flex;
            background-color: #A5C2CB;
            .atkp-sccf-tab-item {
              cursor: pointer;
              margin-right: 20px;
              height: 25px;
              font-weight: 700;
              color: #203041;
              font-size: 12px;
              line-height: 14px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              &:last-child {
                margin-right: 0;
              }
              .atkp-sccdti-line {
                display: none;
                height: 2px;
                background-color: #203041;
                border-radius: 1px 1px 0 0;
              }
              &.atkp-active {
                .atkp-sccdti-line {
                  display: block;
                }
              }
            }
          }
          .atkp-sccf-container {
            height: calc(100% - 26px);
            overflow-y: auto;
            .atkp-sccfc-freq {
              display: none;
              &.atkp-active {
                display: block;
              }
            }
            .atkp-sccfc-tech {
              display: none;
              &.atkp-active {
                display: block;
              }
              .atkp-sccfct-item {
                .atkp-sccfcti-header {
                  padding: 0 16px;
                  .atkp-sccfctih-container {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    padding: 8px 0;
                    border-bottom: solid 1px #8BACB4;
                    img {
                      display: none;
                      width: 24px;
                      margin-right: 8px;
                      &.atkp-active {
                        display: block;
                      }
                    }
                  }
                }
                .atkp-sccfcti-content {
                  display: none;
                  padding: 16px;
                  background-color: #8BACB4;
                  &.atkp-active {
                    display: block;
                  }
                  .atkp-sccfctic-spec {
                    display: flex;
                    align-items: center;
                    margin-bottom: 18px;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                  .atkp-sccfctic-title {
                    width: 91px;
                    font-weight: 700;
                  }
                  .atkp-sccfctic-text {
                    width: 72px;
                    font-size: 12px;
                    margin-left: 8px;
                    &.atkp-title {
                      font-size: 14px;
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
        }
        .atkp-scc-images {
          .atkp-scci-container {
            width: 300px;
            margin: auto;
          }
        }
      }
      .atkp-sc-blind {
        z-index: 10002;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 438px;
        max-height: calc(90vh - 326px);
        background-color: rgba(165,194,203,0.5);
        padding-top: 88px;
        &.atkp-active {
          display: block;
        }
        .atkp-scb-content {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: -10px;
          .atkp-scbc-section {
            width: 119px;
            margin: auto;
            background-color: #8BACB4;
            padding: 16px 18px;
            border-radius: 4px;
            text-align: center;
            .atkp-scbc-title {
              color: #3B4D5D;
              font-size: 10px;
            }
            .atkp-scbc-sub-title {
              font-size: 12px;
              color: #203041;
              font-weight: 700;
              margin-top: 8px;
            }
            .atkp-scbc-order {
              font-size: 88px;
              font-weight: 700;
              color: #203041;
              line-height: 80px;
              margin-top: 6px;
            }
          }
        }
      }
      &.freq-active {
        background-color: #0E4E62;
      }
    }
    .atkp-small-player {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 108px;
      background-color: #203041;
      border-radius: 8px;
      z-index: 10003;
      .atkp-sources-container {
        display: none;
      }
      .atkp-player-container {
        padding: 16px;
        height: 108px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .atkp-audio {
          display: flex;
          align-items: center;
          color: white;
          font-size: 16px;
          cursor: pointer;
          .atkp-icon-music {
            width: 24px;
          }
          .atkp-name-song {
            margin-left: 19px;
          }
          .atkp-name-instrument {
            margin-left: 10px;
            font-weight: 300;
          }
          .atkp-name-empty {
            font-size: 14px;
            margin-left: 19px;
          }
        }
        .atkp-waveform {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .atkp-icon-play {
            cursor: pointer;
            width: 24px;
            img {
              width: 100%;
              &.atkp-inactive {
                display: none;
              }
              &.atkp-active {
                display: block;
              }
            }
          }
          .atkp-wavesurfer {
            width: 300px;
            height: 36px;
          }
          .atkp-wavesuerfer-loading {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 36px;
            z-index: 9999;
            .atkp-wl-container {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              img {
                width: 30px;
                margin: auto;
              }
            }

          }
        }
      }
      &.atkp-sources {
        height: 547px;
        max-height: calc(90vh - 217px);
        .atkp-sources-container {
          display: block;
          height: 438px;
          max-height: calc(90vh - 326px);
          border-bottom: solid 1px rgba(165,194,203,0.2);
          .atkp-sources-header {
            height: 56px;
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .atkp-sources-close {
              cursor: pointer;
              img {
                width: 24px;
              }
            }
          }
          .atkp-sources-tab {
            display: flex;
            align-items: center;
            color: white;
            cursor: pointer;
            img {
              width: 24px;
              &.atkp-inactive {
                display: none;
              }
            }
            span {
              margin-left: 8px;
              font-size: 10px;
            }
          }
          .atkp-sources-content {
            height: 382px;
            max-height: calc(90vh - 382px);
            color: white;
            overflow: hidden;
            position: relative;
            .atkp-scsongs-container {
              height: 100%;
              overflow-y: auto;
            }
            .atkp-sc-atk {
              padding: 0 16px;
            }
            .atkp-sc-my {
              padding: 0 16px;
              .atkp-scm-header {
                padding-top: 10px;
                border-top: solid 1px rgba(255, 255, 255, 0.5);
              }
            }
            .atkp-sources-songs {
              width: 343px;
              padding-right: 171px;
              overflow-y: auto;
              .atkp-songs-item {
                padding: 8px;
                border-radius: 5px 0 0 5px;
                cursor: pointer;
                .atkp-ssi-name {
                  font-size: 16px;
                }
                .atkp-ssi-writer {
                  color: #B0B0B0;
                }
                &.atkp-active {
                  background-color: white;
                  .atkp-ssi-name {
                    color: #203041;
                  }
                  .atkp-ssi-writer {
                    color: #3B4D5D;
                  }
                }
              }
            }
            .atkp-sources-instruments {
              position: absolute;
              top: 0;
              right: 16px;
              width: 171px;
              height: 100%;
              overflow-y: auto;
              background-color: white;
              border-radius: 0 5px 5px 0;
              padding: 8px;
              .atkp-instruments-item {
                padding: 8px;
                border-radius: 4px;
                font-size: 16px;
                color: #203041;
                cursor: pointer;
                &.atkp-active {
                  background-color: #3B4D5D;
                  color: white;
                }
              }
            }
          }

        }
      }
    }
    .atkp-small-products-list {
      position: relative;
      height: 704px;
      background-color: #A5C2CB;
      max-height: calc(90vh - 60px);
      border-radius: 8px;
      padding-top: 80px;
      .atkp-sml-header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 82px;
        padding: 0 16px;
        border-bottom: solid 2px rgba(255,255,255,0.1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        .atkp-sml-info {
          color: #203041;
          .atkp-smlh-title {
            font-size: 24px;
            font-weight: 700;
          }
          .atkp-smlh-desc {
            font-size: 16px;
            margin-left: 8px;
          }
        }
        .atkp-sml-done {
          .atkp-sml-btn {
            width: 64px;
            height: 40px;
            color: black;
            padding: 0;
            border: none 0 white;
            background-color: white;
            font-size: 16px;
            font-weight: 700;
            border-radius: 8px;
          }
        }
      }
      .atkp-sml-container {
        padding: 16px;
        height: 622px;
        max-height: calc(90vh - 142px);
        overflow-y: auto;
        .atkp-smlc-brands {
          display: flex;
          margin-bottom: 30px;
          .atkp-smlcb-item {
            font-size: 16px;
            margin-right: 20px;
            cursor: pointer;
            .atkp-smlcbi-line {
              display: none;
              width: 30px;
              height: 3px;
              border-radius: 1px;
              margin: 8px auto 0;
              background-color: #203041;
            }
            &.atkp-active {
              .atkp-smlcbi-line {
                display: block;
              }
            }
          }
        }
        .atkp-smlc-list {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          &:after {
            content: "";
            width: 96px;
          }
          .atkp-smlc-product {
            width: 96px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            margin: 0 0 24px 0;
            &:nth-child(3n+3) {
              margin-right: 0;
            }
            .atkp-smlcp-container {
              position: relative;
              padding: 12px 0 5px;
              border-radius: 4px;
              text-align: center;
              .atkp-smlcp-image {
                width: 68px;
                height: 68px;
                padding: 3px;
                margin: auto;
                .atkp-smlcp-ibg {
                  width: 62px;
                  height: 62px;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }
              }
              .atkp-smlcp-brand {
                font-size: 10px;
                color: #3B4D5D;
              }
              .atkp-smlcp-model {
                font-size: 12px;
                height: 45px;
                overflow: hidden;
                font-weight: 700;
                line-height: 14px;
                color: #203041;
                display: flex;
                flex-direction: column;
                justify-content: center;
              }
              .atkp-smlcp-price {
                font-size: 12px;
                color: #3B4D5D;
              }
              .atkp-smlcp-order {
                display: none;
                position: absolute;
                top: -10px;
                right: -10px;
                width: 20px;
                height: 20px;
                background-color: white;
                border-radius: 50%;
                color: black;
              }
            }
            &.atkp-active {
              .atkp-smlcp-container {
                background-color: #203041;
                .atkp-smlcp-brand, .atkp-smlcp-price {
                  color: #B0B0B0;
                }
                .atkp-smlcp-model {
                  color: white;
                }
                .atkp-smlcp-order {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
    .atkp-small-session {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10008;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .atkp-ss-container {
        position: relative;
        background-color: #F7F7FC;
        padding: 24px 18px;
        width: 300px;
        margin: -50px auto 0;
        border-radius: 8px;
        text-align: center;
        .atkp-ssc-icon {
          margin: auto;
          width: 48px;
          img {
            width: 100%;
          }
        }
        .atkp-ssc-title {
          font-size: 18px;
          font-weight: 700;
          margin-top: 16px;
        }
        .atkp-ssc-desc {
          font-size: 18px;
          font-weight: 300;
          margin-top: 24px;
        }
        .atkp-ssc-link {
          font-weight: 700;
          margin-top: 32px;
          a {
            color: black;
            text-decoration: none;
          }
        }
        .atkp-ssc-copy {
          text-align: center;
          margin-top: 16px;
          button {
            width: 110px;
            height: 40px;
            line-height: 38px;
            padding: 0;
            margin: 0;
            background-color: #203041;
            border: solid 1px #203041;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 700;
            color: white;
          }
        }
        .atkp-ssc-copied {
          text-align: center;
          margin-top: 16px;
          button {
            width: 110px;
            height: 40px;
            line-height: 38px;
            padding: 0;
            margin: 0;
            background-color: white;
            border: solid 1px #A5C2CB;
            border-radius: 6px;
            font-size: 16px;
            font-weight: 700;
            color: #203041;
          }
        }
        .atkp-ssc-close {
          position: absolute;
          top: 8px;
          right: 8px;
          cursor: pointer;
          img {
            width: 24px;
          }
        }
        .atkp-ssc-shadow {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(216, 216, 216, 0.68);
          .atkp-sscs-container {
            position: relative;
            width: 100%;
            height: 100%;
            .atkp-sscsc-loading {
              width: 30px;
              height: 30px;
              position: absolute;
              top: 50%;
              left: 50%;
              margin-top: -15px;
              margin-left: -15px;
            }
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .atkp-small-shadow {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(32, 48, 65, 0.5);
      z-index: 10006;
    }
    .atkp-small-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(165, 194, 203, 0.5);
      z-index: 10006;
      .atkp-sl-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        img {
          width: 50px;
          margin: auto;
        }
      }
    }
  }
}
.atkp-infos {
  position: fixed;
  right: 36px;
  bottom: 36px;
  color: black;
  text-align: left;
  .atkp-infos-container {
    position: relative;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
    width: 375px;
    height: 764px;
    max-height: 90vh;
    border-radius: 8px;
    overflow: hidden;
    background-color: #F7F7FC;
    .atkp-infos-header {
      height: 155px;
      padding: 15px 30px;
      background-image: url('https://atk-player.s3.amazonaws.com/backgrounds/infos_header.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      .atkp-ih-title {
        font-size: 13px;
        font-weight: 300;
        color: #4F4F4F;
      }
      .atkp-ih-logo {
        width: 280px;
        margin: 16px auto 0;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
    .atkp-infos-tabs {
      height: 32px;
      padding: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img {
        width: 24px;
        cursor: pointer;
      }
    }
    .atkp-infos-content {
      height: 577px;
      max-height: calc(90vh - 187px);
      padding-bottom: 100px;
      .atkp-ic-desc {
        height: 100%;
        overflow-y: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: white;
        font-size: 16px;
        .atkp-icd-content {
          padding: 20px 16px;
          min-height: 100%;
        }
        &.atkp-icd-home {
          background-image: url('https://atk-player.s3.amazonaws.com/backgrounds/infos_home.png');
        }
        &.atkp-icd-listening {
          background-image: url('https://atk-player.s3.amazonaws.com/backgrounds/infos_listening.png');
        }
        &.atkp-icd-about {
          background-image: url('https://atk-player.s3.amazonaws.com/backgrounds/infos_about.png');
        }
        &.atkp-icd-faq {
          background-image: url('https://atk-player.s3.amazonaws.com/backgrounds/infos_faq.png');
        }
      }
    }
    .atkp-infos-footer {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      .atkp-if-container {
        height: 110px;
        border-radius: 8px 8px 0 0;
        background-image: url('https://atk-player.s3.amazonaws.com/backgrounds/infos_footer.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        .atkp-ifc-list {
          height: 100%;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .atkp-ifc-item {
            cursor: pointer;
          }
          .atkp-ifc-link {
            a {
              color: black;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .atkp-small {
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    .atkp-small-container {
      margin: auto;
    }
  }
  .atkp-infos {
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    .atkp-infos-container {
      margin: auto;
    }
  }
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
.atkp-small {
  .atkp-small-container {
    .atkp-small-content {
      .atkp-sc-container {
        .atkp-scc-listen {
          .atkp-sccl-marked {
            h3 {
              font-family: 'Lato', sans-serif;
              font-weight: 700;
              font-size: 16px;
              line-height: 22px;
              color: black;
            }
            p {
              font-weight: 300;
              font-size: 16px;
              line-height: 22px;
              color: black;
            }
          }
        }
      }
    }
  }
}
.atkp-infos {
  .atkp-infos-container {
    .atkp-infos-content {
      .atkp-ic-desc {
        .atkp-icd-content {
          h3 {
            font-family: 'Lato', sans-serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: white;
          }
          p {
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;
            color: white;
          }
        }
      }
    }
  }
}
</style>
