<template>
  <div id="atk_player_app">
    <Player :instance="appInstance" :status="appStatus" :params="appParams" />
  </div>
</template>

<script>
import Player from './views/Player.vue'
export default {
  name: 'App',
  props: {
    instance: String,
    status: String,
    params: Object
  },
  components: {
    Player
  },
  data () {
    return {
      appInstance: this.instance,
      appStatus: this.status,
      appParams: this.params
    }
  },
  watch: {
    instance: {
      handler: function (val, oldVal) {
        if (val !== oldVal) this.appInstance = val
      },
      deep: true
    },
    status: {
      handler: function (val, oldVal) {
        if (val !== oldVal) this.appStatus = val
      },
      deep: true
    },
    params: {
      handler: function (val, oldVal) {
        if (val !== oldVal) this.appParams = val
      },
      deep: true
    }
  },
  created: function () {
  }
}
</script>

<style>
</style>
