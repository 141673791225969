import axios from 'axios'
import config from '@/config/index.js'

// create axios instance
const service = axios.create({
  // eslint-disable-next-line
  baseURL: config.apiHost, // set global url
  timeout: 30000 // timeout
  // withCredentials: true // cors config
})
// request interceptor
service.interceptors.request.use(
  config => {
    // config.headers.Authorization = `Bearer ${getToken()}`
    return config
  },
  error => {
    Promise.reject(error)
  }
)
// response interceptor
service.interceptors.response.use(
  response => {
    if (response) {
      const data = response.data
      switch (data.code) {
        case 401:
        case 403:
          // login fail
          // store.dispatch('logout').then(() => {
          //   ElMessage({
          //     message: 'Authorization expired'
          //   })
          //   router.replace('/login')
          // }).catch(err => {
          //   console.error(err)
          // })
          break
      }
      return data
    } else {
      Promise.reject(new Error('error'))
    }
  },
  err => {
    // if (JSON.stringify(error).includes("401")) {
    //   alert("Auth expired, please sign in again.")
    // } else {
    //   alert(error)
    // }
    const res = {
      code: -1,
      msg: 'Network error'
    }
    // console.warn(err.response)
    if (err.response) {
      const data = err.response.data
      if (data) {
        Object.assign(res, {
          code: data.code,
          msg: data.msg
        })
      }
      // client received an error response (5xx, 4xx)
    } else if (err.request) {
      // client never received a response, or request never left
    } else {
      // anything else
    }
    return Promise.reject(res)
  }
)
export default service
